.header-cell {
  background-color: #293462;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.5rem;
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
}

.body-cell {
  font-weight: 500;
  line-height: 1.5rem;
  display: table-cell;
  padding: 16px;
  font-size: 0.875rem;
  text-align: left;
}
.positive {
  color: #008000;
}
.negetive {
  color: #b20101;
}
.hyperlink {
  color: #2b60de;
}
.table-row {
  border-bottom: 2px solid #ffffff;
}
.table-row:nth-last-child() {
  border-bottom: none;
}
.table-row:nth-child(even) {
  background-color: #f5f5f5;
}
.sub-body-cell {
  line-height: 1px;
  text-align: end;
  font-weight: bold;
  padding: 10px;
}
.children-body-cell {
  display: grid;
}
.layout {
  max-height: 73vh;
  overflow-y: auto;
}
.emptyCell {
  font-weight: bold;
  text-align: center;
  font-size: larger;
}
