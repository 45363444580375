.cashBalancePopupCss {
  width: 547px;
  overflow-x: auto;
  max-height: 687px;
}
.cashBalancePopup table tr:nth-child(7) {
  background: #293462;
  color: #fff;
}
.cancelBtnPopup {
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  height: 56px;
  width: 242px;
  left: -0.5px;
  top: 12px;
  border-radius: 4px;
  padding: 14px, 24px, 14px, 24px;
}
