.container {
  height: 80vh;
  overflow-y: auto;
}

.left-navigation {
  background-color: #ffffff;
  max-height: 100vh;
}

.grid-box {
  padding: 16px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 0px;
}

.header-h3 {
  padding: 16px;
  font-size: 25px;
  font-weight: 700;
  margin: 0 23px;
}

.nav-list {
  margin: 0 25px !important;
  font-size: 21px;
  font-weight: 400;
}

.nav-hover:hover {
  background-color: #293462 !important;
  color: #ffffff;
}

.nav-selected {
  margin: 0 25px;
  padding: 16px;
  background-color: #293462;
  color: #ffffff;
}
/* @media screen and (max-height:800px) { */
.right-div {
  margin-left: 40px !important;
  margin-top: 30px !important;
  position: relative;
  height: 80vh;
  overflow: auto;
}

.divider {
  margin-bottom: 0px !important;
}
.count-part {
  font-weight: 700;
  font-size: 18px;
}
.count-part-content {
  margin-bottom: 1px !important;
  font-weight: 400;
  font-size: 18px;
}
.div-button {
  padding: 22px;
}
.back-icon {
  color: #293462;
}
.button-count {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #293462;
}

footer {
  position: fixed;
  width: 100%;
  height: 9%;
  margin-top: "calc(10% + 60px)";
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: "2px -2px 8px 0px #00000040";
}

.butn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.box-page {
  position: fixed;
  top: 84%;
  left: 50%;
  background-color: transparent !important;
}

.button1 {
  margin-right: 20px;
}
.button3 {
  background: #293462;
  margin-left: 20px;
  color: #ffffff !important;
}
.button1,
.button2,
.button3 {
  height: 56px;
  width: 187px;
  border-radius: 4px;
  padding: 0px 56px 0px 56px;
  color: #293462;
  border: 2px solid #293462;
  font-size: 18px;
  font-weight: 700;
}

.selected {
  background-color: #293462 !important;
  /* 293462 */
}
