.burglary-button {
  background-color: #293462;
  font-weight: 700;
  font-size: 24px;
  min-width: 172px;
  min-height: 72px;
  align-self: center;
  color: white;
}

.burglary-button:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
  border-radius: 4px;
  color: #d0d3d4;
}
