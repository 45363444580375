.linkTag {
  color: #2b60de;
}
.filterInputSearchBox {
  background: #ffffff;
  border: 1px solid #444444;
  border-radius: 4px;
  align-items: center;
  padding: 14px 16px;
  gap: 16px;
  width: 100%;
  height: 56px;
}
.incorrectFormat {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #b20101;
}
.incorrectFormatBorder {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #f7ced7;
  /* UI/Danger Red */

  border: 1px solid #b20101 !important;
  border-radius: 4px;
}
.filterNode {
  vertical-align: bottom;
}
