.MuiOutlinedInput-input {
  padding: 16px 14px;
}
.po-date-picker .innerContainer i {
  right: 18px;
}
.MuiPickersToolbar-toolbar {
  background-color: var(--primary-blue) !important;
}
.MuiPickerDTTabs-tabs {
  background-color: var(--primary-blue) !important;
}
.MuiPickersDay-daySelected {
  background-color: var(--primary-blue) !important;
}

.MuiPickersClockPointer-thumb {
  border: 15px solid var(--primary-blue) !important;
}
.MuiPickersClockPointer-pointer {
  background-color: var(--primary-blue) !important;
}
.MuiPickersClock-pin {
  background-color: var(--primary-blue) !important;
}
/* .MuiInputBase-input{
    height: 15px !important;
    border: 1px solid #444444 !important;
    box-sizing: content-box;
    border-radius: 4px !important;
} */
/* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 16px;
    border: 1px solid #444444;
    box-sizing: content-box;
    border-radius: 4px; */
