.lebalText {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}

.inputRecordfield {
  height: 72px;
  max-width: 512px;
  left: 0px;
  top: 50px;
  border-radius: 4px;
}

.DetailView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  font-size: 18px;
  line-height: 28px;
  margin: 15px 0px;
}

.subheadingPopup {
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}

.mainheading {
  font-family: Nunito Sans;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}

.popupconfirmmodel {
  position: static;
  width: 560px;
  height: 356px;
  left: 680px;
  top: 267px;
}

.SystemTable {
  line-height: 50px;
  font-size: 24px;
}

.mainHeadingRecord {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;

  color: #444444;
}

.subHeadRecord {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #222222;
}

.errorTextRecord {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #dc381f;
}

.maxwidth-512 {
  max-width: 512px;
}

.width51 {
  width: 51%;
}

.width49 {
  width: 49%;
}

.imageSize {
  width: 73.33px;
  height: 63.33px;
}

.popupBoxSize {
  width: 760px;
  height: 496px;
}

.completePopupBtn {
  font-family: Nunito Sans;
  font-size: 27px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

  height: 96px;
  width: 406px;
  left: 0px;
  top: 0px;
  border-radius: 6px;
  padding: 27px 36px 27px 36px !important;
}
ul.popupLabel {
  list-style: disc;
}
.dangerIcon {
  padding-top: 2px;
  width: 26.67px;
  height: 26.67px;
}
.BtnSize {
  width: 199px;
  height: 72px;

  font-size: 24px;
  font-weight: 700;
  line-height: 34px;

  justify-content: center;
  align-items: center;
  padding: 0px 56px;
}
.recordVarianceBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 0px;
  gap: 24px;

  position: absolute;
  width: 100%;
  left: 0px;
  bottom: 55px;
  background: #ffffff;
  box-shadow: 2px -2px 8px rgba(0, 0, 0, 0.25);
}
