.bg-white {
  background-color: var(--white);
}

.popupBg {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
}
.popupContent {
  position: relative;
  top: 40%;
  margin: 0 auto;
  width: 50%;
  padding: 25px;
  height: 20vh;
  background-color: #fff;
}
.table-border {
  border: 1px solid #d0d3d4;
  border-radius: 4px;
}
.btnCss {
  margin: 10px 15px 0 20px;
  width: 60px;
}
.cursor {
  cursor: pointer;
}
.tabs {
  border-bottom: 1px solid #b5b5b5;
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.tabs li {
  display: inline-block;
  cursor: pointer;
  color: #222222;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
  padding: 10px 30px;
  top: 1px;
}
.tabs li:focus {
  outline: none;
}
.tabs li:hover {
  color: var(--primary-blue);
  transition: 0.2s;
  border-bottom: var(--primary-blue) 2px;
}
.tabs li.active {
  color: var(--primary-blue);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  border-bottom: var(--primary-blue) solid 2px;
  position: relative;
  top: 1px;
}

.viewtabs {
  border-bottom: 1px solid #b5b5b5;
  list-style: none;
  padding: 0;
}
.viewtabs li {
  display: inline-block;
  cursor: pointer;
  color: #222222;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  position: relative;
  padding-right: 5px;
  padding-left: 5px;
  top: 1px;
  margin-left: 15px;
  margin-right: 15px;
}

.viewtabs li:focus {
  outline: none;
}
.viewtabs li:hover {
  color: var(--primary-blue);
  transition: 0.2s;
  border-bottom: var(--primary-blue) solid 4px;
}
.viewtabs li.active {
  color: var(--primary-blue);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  border-bottom: var(--primary-blue) solid 4px;
  position: relative;
  top: 1px;
}

.tabRow {
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 0px 5px 0px;
  background: #ffffff;
  border-bottom: 1px solid #e9eaeb;
  box-sizing: border-box;
}
.tabRowTaskHistory {
  font-size: 14px;
  line-height: 16px;
  padding: 8px 0px 5px 0px;
  background: #ffffff;
  border-bottom: 1px solid #e9eaeb;
  box-sizing: border-box;
}
.leftText {
  padding: 10px 10px;
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  font-weight: 700;
  background: var(--primary-blue);
  color: var(--white);
}

.Overdue {
  width: 83px;
  line-height: 21px;
  padding: 3.5px 2px;
  text-align: center;
  font-size: 14px;
  background: var(--orange);
  font-weight: bold;
  color: #ffffff;
  border: 1px solid var(--orange);
  box-sizing: border-box;
  border-radius: 100px;
}
.Late {
  width: 83px;
  line-height: 21px;
  padding: 3.5px 2px;
  text-align: center;
  font-size: 14px;
  background: var(--red);
  font-weight: bold;
  color: #fff;
  border: 1px solid var(--primary-blue);
  box-sizing: border-box;
  border-radius: 100px;
}
.Pending {
  width: 83px;
  line-height: 21px;
  padding: 3.5px 2px;
  text-align: center;
  font-size: 14px;
  background: var(--grey);
  font-weight: bold;
  color: #444444;
  border: 1px solid var(--grey);
  box-sizing: border-box;
  border-radius: 100px;
}

.Due {
  width: 83px;
  line-height: 21px;
  padding: 3.5px 2px;
  text-align: center;
  font-size: 14px;
  background: #2b60de;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #2b60de;
  box-sizing: border-box;
  border-radius: 100px;
}
.Completed {
  line-height: 21px;
  padding: 3.5px 10px;
  text-align: center;
  font-size: 14px;
  background: #008000;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #008000;
  box-sizing: border-box;
  border-radius: 100px;
}

.Incomplete {
  line-height: 21px;
  padding: 3.5px 10px;
  text-align: center;
  font-size: 14px;
  background: #dc381f;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #dc381f;
  box-sizing: border-box;
  border-radius: 100px;
}

.marginTop {
  margin-top: 30px;
}
.tableFont {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 28px;
  margin: 0px 10px 0 0;
}
.adhod-heading {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}

.balance-check {
  font-size: 26px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 0px !important;
  width: max-content;
}
.width-67 {
  width: 67%;
}
.width-21 {
  width: 21%;
}
.width-98 {
  width: 98%;
}
.width-97 {
  width: 97%;
}
.width-10 {
  width: 10%;
}
.next-background,
.reset-btn {
  color: #d0d3d4;
  padding: 19px 56px;
  font-size: 24px;
  line-height: 34px;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
}

.active-next {
  color: var(--white);
  padding: 19px 56px;
  font-size: 24px;
  line-height: 34px;
  background: var(--primary-blue);
}

.MoveToDiscripancyDisable {
  color: #b6b6b4;
  padding: 14px 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    var(--primary-blue);
}
.removeCashDrawerDisable {
  pointer-events: none;
  opacity: 0.4;
}

.boxShadow {
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
}

.accordion {
  list-style: none;
  width: min-content;
}
.tableLeftPadding {
  padding-left: 12px;
}

.tableCheckBox {
  vertical-align: middle;
  width: 24px;
  height: 24px;
}

.button {
  font-size: 18px;
  background-color: transparent;
  color: #222;
  text-align: left;
  font-weight: bold;
  width: 100%;
  padding: 14px 8px;
  cursor: pointer;
  line-height: 18px;
  border: none;
}
.textPopupReason {
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  width: 90%;
}

.completeBtnPopup {
  font-family: Nunito Sans;
  font-size: 27px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;

  height: 96px;
  width: 193px;
  left: 0px;
  top: 0px;
  border-radius: 6px;
  padding: 27px 36px 27px 36px !important;
}

.imageCheckSize {
  height: 44.70000457763672px;
  width: 58.633331298828125px;
  left: 10.683349609375px;
  top: 17.64990234375px;
  border-radius: 0px;
}

.testSizeVariancePopup {
  font-family: Nunito Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
}
.button {
  border-radius: 4px;
  min-width: max-content;
}

.control {
  font-size: 20px;
  padding-left: 15px;
}

.answer {
  background-color: transparent;
  padding: 9px 10px 20px 15px;
}

.accordion_item.active .button {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
  border-radius: 4px;
}
.activeSubMenu {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
  border-radius: 4px;
}

.activeSubMenu:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
}

.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
.sub-menu-border-left {
  border-left: 1px solid #d0d3d4;
}

.sub-menu-border-left li {
  display: block;
  font-size: 15px;
  padding: 14px;
  color: #444444;
  line-height: 18px;
  border-radius: 4px;
}

.closeLeftMenu {
  text-align: right;
}
.headerMenu li {
  background: #fff;
  border: 1px solid #d0d3d4;
  box-sizing: border-box;
  padding: 19px 27px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  color: #222222;
}
.headerMenu li:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
}
/* .varianceSummary {
  height: 340px;
  overflow: auto;
} */
.testSizePopupHeading {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
.chatClassouter {
  border: 2px solid #ddd;

  background-color: #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 500px;
  width: 400px;
}
.chatClassinner {
  display: flex;
  justify-content: space-between;
}
.sidebarBorder {
  background: var(--primary-grey);
  border: 1px solid #d0d3d4;
  box-sizing: border-box;
}

.shadowimg {
  box-shadow: 0px 0px 10px 0px;
  bottom: 15px;
  right: 25px;
  width: 82px;
  background-color: #6f6cbd;
}

.restNoBtn {
  height: 48px;
}
.restNoBtnMcd {
  height: 75px;
}

.sidebarclass {
  top: 65px;
  left: 0;
  position: absolute;
}
.account-group {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
  color: #222222;
  margin: 10px 0px;
}
.overviewTab {
  display: inline-flex;
  float: right;
}
.accounting-card-label-name {
  font-size: 21px;
  line-height: 30px;
  padding-top: 1rem;
  font-weight: 700;
  font-style: normal;
}

.accounting-card .heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--primary-blue);
  padding: 4px 15px;
  border-top-left-radius: 10px;
  white-space: normal;
  border-top-right-radius: 10px;
}
.accounting-card .heading i {
  color: #999;
}
.accounting-card .heading span {
  font-size: 15px;
  line-height: 28px;
  color: var(--white);
  font-weight: 700;
}
.accounting-card .main {
  border-radius: 10px;
  border: 1px solid #d0d3d4;
}

.accounting-card .second-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 15px;
  background-color: var(--white);
}

.accounting-card .second-heading i {
  color: #999;
}

.accounting-card .second-heading span {
  font-size: 17px;
  line-height: 28px;
  color: #222;
  font-weight: 400;
}
.third-heading {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 28px;
  color: #222;
  background-color: var(--white);
  align-items: flex-start;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Safe table tr {
  border-bottom: 1px solid #d0d3d4;
}

.Safe table tr:last-child {
  border: none;
}

.Safe table td {
  font-size: 18px;
  color: #222;
  font-weight: 400;
  padding: 2px 15px;
}

.btnSubMenu {
  text-align: left;
  width: 100%;
}

.morebtn {
  background: #e9e9eb;
  border-radius: 10px;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
}
.AddRemoveBtn {
  width: 400px;
  height: 172px;
}
.balanceLayout {
  width: 348px;
  height: 90px;
}
.popupmodel {
  width: 482px;
  height: 272.12px;
}

.safeBalanceHeight {
  width: 348px;
  height: 98px;
}
.widthAccounting {
  width: 350px;
}

.redLabel td {
  color: #dc381f !important;
}

.greenLabel td {
  color: #008000 !important;
}
.redLabel {
  color: #dc381f !important;
}

.greenLabel {
  color: #008000 !important;
}
.safeHeight tr {
  height: 64.67px;
}
tr.border_bottom td {
  border-bottom: 1px solid #d0d3d4;
}
.safeNwidth {
  width: 449px;
}

.testSizePopup {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
}
.testSizeSubHead {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
}
.popupContainer {
  min-width: 604px;
  max-height: 811px;
  height: auto;
}
.dropDownWidth80 {
  width: 80%;
}
.dropDownCss {
  font-family: Nunito Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
.popupTextSize {
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 34px;
}
.popupHead {
  /* min-width: 540px; */
  height: 80px;
}
.counterPad tr td {
  padding: 1px 5px 1px 15px;
}
@media screen and (max-width: 1300px) {
  .widthAccounting {
    width: 400px;
  }
}

.width-63 {
  width: 63%;
}
.width-37 {
  width: 37%;
}

.width-15 {
  width: 15%;
}

/* .taskTab li {
    display: inline-block;
    cursor: pointer;
    color: #222;
    width: 102px;
    border: 1px solid #222;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 700;
    line-height: 24px;
    padding: 10px 30px;
}

.taskTab li:focus {
  outline: none;
}
.taskTab li:nth-child(1){
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.taskTab li:nth-child(2){
  width: 115px;
  border-left: none;
  border-radius: 0px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.taskTab li.active {
  background: #222222;
  color: #fff;
} */

.tabRow:nth-of-type(odd) {
  background-color: #fff;
}

.tabRow:nth-of-type(even) {
  background-color: #fafbfb;
}

.mainContainerShadow {
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);
}

.tabRowTaskHistory:nth-of-type(odd) {
  background-color: #fff;
}

.heading {
  font-size: 1.125rem;
}

.heading-2 {
  font-size: 1.313rem;
}
.heading-3 {
  font-size: 1.688rem;
}

.heading-4 {
  font-size: 1.5rem;
}

.heading-5 {
  font-size: 1.875rem;
}

.heading-6 {
  font-size: 1rem;
}

.transfer-out-table td {
  padding: 15px 0px;
  border-bottom: 1px solid #d0d3d4;
}

.transfer-out-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background: #fff;
}

.warningMsg {
  color: #dab702;
}

.errorMsg {
  color: #dc381f;
}

.warningMsgBorder {
  border: 1px solid #dab702 !important;
}

.warningMsgBorder:focus-visible {
  border: 1px solid #dab702 !important;
  outline: none;
  background: rgba(255, 216, 1, 0.05) !important;
}

.errorMsgBorder {
  border: 1px solid #dc381f !important;
  outline: none;
  background: #fcf2f1 !important;
}

.errorMsg:focus-visible {
  outline: none;
  border: 1px solid #dc381f !important;
}

.CashTransfer {
  padding: 15px 25px;
  border: 1px solid #d0d3d4;
  box-sizing: border-box;
  border-radius: 4px;
  overflow-y: auto;
}

.CashTransferNoBorder {
  padding: 15px 25px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow-y: auto;
}

.transferIn-right-side {
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
}

.transferIn-left-side {
  height: calc(100vh - 296px);
}

.transferOut-center-side {
  height: calc(100vh - 225px);
}

.greyBack {
  background: #ffffff;
  box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid #d0d3d4;
  box-sizing: border-box;
  border-radius: 4px;
  overflow-y: auto;
  padding: 30px;
}
.transfer-out-tiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 70px;
  background: #ffffff;
  border: 2px solid #d0d3d4;
  box-sizing: border-box;
  box-shadow: 0px 7.5px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}

.active-counter {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #2e1a47 !important;
}

.transfer-out-section-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
}
.tabRowTaskHistory:nth-of-type(even) {
  background-color: #fafbfb;
}

.sidebarBtnCss {
  width: 26.58px;
  height: 24.77px;
}

.transferbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: #fff;
}

.transferbutton {
  border-bottom: 1px solid #d0d3d4;
}

.transferbutton:last-child {
  border-bottom: none !important;
}
.transferButtonActive {
  background: #ccedf3 !important;
}

.loaderCss {
  min-width: 560px;
  height: 216px;
}
.imgCenter {
  margin-left: auto;
  margin-right: auto;
  top: 25px;
}

.loaderText {
  font-family: Nunito Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
}

.transferIntabs {
  border-bottom: 4px solid var(--primary-blue);
  font-size: 21px;
  line-height: 28px;
  color: var(--primary-blue);
  font-weight: 700;
}
.chevron {
  width: 5px;
  height: 10px;
}
.textColor {
  color: #444444;
}
