:root {
  --primary-blue: #293462;
  --primary-grey: #d4d6e0;
  --blue: #2b60de;
  --white: #ffffff;
  --red: #b20101;
  --positive-green: #008000;
  --black: #223445;
  --primary-black: #252a36;
  --orange: #ff9500;
  --grey: #d0d3d4;
  --warningYellow: #ffd801;
  --voilet: #2e1a47;
  --black-grey: #222;
  --disablegrey: #a9a5a4;
}
