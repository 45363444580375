.burglary-heading {
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 10px;
}
.burglary-input-container {
  display: flex;
  flex-direction: column;
  font-size: 24px;
}
.burglary-input {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 72px;
  border-radius: 5px;
  padding-left: 10px;
  border: 1px solid #000000;
}
.burglary-button {
  background-color: #293462;
  font-weight: 700;
  font-size: 24px;
  min-width: 172px;
  min-height: 72px;
  align-self: center;
  color: white;
}

.burglary-button:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
  border-radius: 4px;
  color: #d0d3d4;
}
