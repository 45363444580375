.amountInput {
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 18px;
  line-height: 28px;
  color: #2e1a47;
}
