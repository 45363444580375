.forex-den-completed-alert-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
}

.variance {
  font-weight: 700;
  font-size: 24px;
  /* line-height: 10px; */
  text-align: center;
  color: #444444;
}
.variance-value {
  color: #b20101;
}
.variance2 {
  padding-top: 20px;
  font-size: 24px;
  text-align: center;
  color: #444444;
}
.description {
  /* margin-top: 40px; */
  font-size: 24px;
  font-weight: bold;
}
