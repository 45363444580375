@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./_Styles_/variable.css";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
@import "./_Styles_/variable.css";
body {
  font-family: "Nunito Sans";
  background-color: var(--primary-grey);
}
.padding-90 {
  padding-top: 90px;
}
.cashAlign {
  margin-top: 15px;
}
.head-background {
  background: var(--primary-grey);
}
.subheading {
  display: flex;
  flex-direction: row;
  color: #fff;
  align-items: center;
  padding: 6px 20px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), var(--primary-blue);
  height: 3rem;
}
.textCash {
  width: 20%;
  float: left;
}
.noRecords {
  height: 500px;
  text-align: center;
  font-weight: bold;
  font-size: 21px;
}
.textCashalign {
  white-space: nowrap;
  width: auto;
  padding-right: 5px;
}
.textCashType {
  white-space: nowrap;
  width: 30%;
  float: left;
}
.signX {
  width: 50%;
  float: right;
}
.alertCss {
  width: 100%;
  border: 1px solid red !important;
  background: #fcf2f1;
}
.successCss {
  width: 100%;
  background: #daf7d6 !important;
  border: 1px solid #008000 !important;
}
.normalCss {
  width: 100%;
}
.normalCss[disabled] {
  background: #f4f4f4 !important;
}

.inputCash {
  outline: none;
}
.border-4px {
  border-radius: 4px;
}
.border-spacing-zero {
  border-spacing: 0px;
}
.resetBtn {
  padding: 12px 24px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  border: 2px solid #d0d3d4;
}
.bg-red-700:focus {
  --tw-bg-opacity: 0.1;
  background-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.taskheight {
  height: calc(100vh - 140px);
}
.hover:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.font-24 {
  font-size: 24px;
}
.brand_button {
  padding: 14px 24px;
}
.brandRedBtnMcd {
  height: 75px;
  background-color: var(--primary-blue) !important;
  padding: 14px 24px;
}
.brandRedBtn {
  background-color: var(--primary-blue) !important;
  padding: 14px 24px;
  border-radius: 4px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  font-weight: 700;
}
.brandRedBtn:hover {
  background-color: var(--primary-blue) !important;
  padding: 14px 24px;
  border-radius: 4px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  font-weight: 700;
}
.brandRedBtn:active {
  background-color: var(--primary-blue) !important;
  padding: 14px 24px;
  border-radius: 4px;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  font-weight: 700;
}
.brandRedBtn:focus {
  background-color: var(--primary-blue) !important;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  padding: 14px 24px;
  border-radius: 4px;
  font-weight: 700;
}
.balanceborder {
  margin-top: 7rem;
}
.displayNone {
  display: none;
}
.maintasklist {
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #d0d3d4; */
  height: auto;
  overflow: auto;
  min-width: 800px;
}
.maintasklistouter {
  overflow: hidden;
  height: 86vh;
  margin-top: 5px;
}
.accounting-button {
  background: var(--primary-blue);
  box-shadow: 0px 7.58px 6.06px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.accounting-button:disabled,
.accounting-button[disabled] {
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}
.accountingBtnNoHover {
  background: var(--primary-blue);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}

.btn-submit-cash {
  width: 164px;
  height: 56px;
}

.text-color {
  color: #222;
}
.accounting-button:hover {
  opacity: 0.7;
}
.accounting-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  font-size: 18px;
  line-height: 28px;
  margin: 15px 0px;
}
.brandWhiteBtn {
  padding: 14px 16px;
  background: #ffffff;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 18px;
  line-height: 28px;
  color: #2e1a47;
}
.success-button {
  background: #008000;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  font-size: 18px;
  line-height: 28px;
  padding: 14px 24px;
}
.diableBtnCss {
  cursor: auto;
  /* opacity: 0.4; */
}
.nonCursor {
  cursor: auto;
}
.padding-14 {
  padding: 14px;
}
.height-80 {
  height: 80vh;
}
.height-86 {
  height: 86vh;
}
.height-65 {
  height: 65vh;
}
.height-82 {
  height: 82vh;
}
.height-39 {
  height: 39rem;
}
.boxLadderShadow {
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.25);
  background: var(--white);
}
.buttomBtnFix {
  /* background: #fff; */
  position: sticky;
  bottom: 0;
}
.paidTextArea {
  background: #ffffff;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
}
.paidTextAreaError {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #f7ced7;
  border: 1px solid #dc381f;
  box-sizing: border-box;
  border-radius: 4px;
}
.transferOut-height {
  /* background-color: #ffffff; */
  height: calc(100vh - 96px);
}
.ladderHeight {
  height: 55vh;
}
.height-95 {
  height: 95%;
}
.height-500 {
  height: 500px;
}
.height-94 {
  height: 94%;
}
.width-45 {
  width: 45%;
}
.width-55 {
  width: 55%;
}
.width-15 {
  width: 15%;
}
.width-8 {
  width: 8%;
}
.width-10 {
  width: 10%;
}
.width-960 {
  width: 960px;
}
.width-1000 {
  width: 1000px;
}
.width-70 {
  width: 70%;
}
.transferOutHeight {
  height: 89%;
}
.accounting-card .main:last-child {
  margin-right: 0px !important;
}
.width-100 {
  width: 100px;
}
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5rem 1.5rem;
}
.white-bg {
  background-color: #fff !important;
  color: #222222;
  border: 1px solid #444444;
  box-sizing: border-box;
}
.varianceBox {
  height: 200px;
  width: 70%;
  padding: 10px;
}

.paidTickColor {
  color: #008000;
}

.paidWarningColor {
  color: #2b60de;
  background-color: #ffffff;
}
.paidCrossColor {
  color: #e40046;
}
.iconBlock {
  height: 70%;
}

.textfonterr {
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.textArea {
  padding: 10px;
}

.counterSelect {
  height: 535px;
  overflow: auto;
}
.width50 {
  width: 50%;
}
.arrowSelect {
  background-image: url("../assets/images/downarrow.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  width: 30px;
  height: 22px;
}

.filterDropdown {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../assets/images/downarrow.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  width: 100%;
  background-size: 20px 10px;
}
.width-180 {
  width: 180px;
}
.cashbalanceDate {
  background-image: url("../assets/images/date.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  background-size: 20px 20px;
}

.movementTable tbody tr:nth-child(odd) {
  background-color: #fafbfb;
}
.movementTable tr td {
  padding: 10px;
}

.breakdownTable thead tr {
  background-color: #dfdfdf;
}
.breakdownTable tbody tr:nth-child(even) {
  background-color: #fafbfb;
}
.breakdownTable tr td {
  padding: 6px 6px 6px 15px;
}
.breakdownTable tr th {
  padding: 11px;
}
.breakdownTableBorder {
  border: 1px solid #979797;
}

.pouchPopup th {
  padding: 14px 10px;
}

.pouchPopup tbody tr td {
  padding: 10px;
}

.pouchPopup tbody tr:nth-child(odd) {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #d0d3d4;
}

.colorBlue {
  color: #2b60de;
}

.pouchPrepBox {
  border: 1px solid #c8bdbd;
  box-sizing: border-box;
  border-radius: 8px;
}

.movementInput {
  background: #ffffff;
  border: 1px solid #979797;
  box-sizing: border-box;
  padding: 24px 16px;
  background: #ffffff;
  border-radius: 4px;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
}

.flex-flow {
  flex-flow: row wrap;
}
.autoflex {
  flex-grow: 1;
  overflow-y: auto;
}
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
  color: #2b60de;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}
.disabledBtn {
  color: gray !important;
  cursor: default;
}
.link-button-discrepancy {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: #2b60de;
}
.link-buttonDisabled {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  color: #808080;
}
.loader {
  width: 40%;
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 216px;
  padding: 32px 24px;
  top: 30%;
  left: 32%;
  background: #fff;
}
.loaderColor {
  color: #2b60de;
}
.loader h2 {
  font-family: Nunito Sans;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #222222;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.border-none {
  border: none !important;
}

.branchOverviewButton {
  background: var(--primary-blue);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
}

.btn-disable:disabled,
.btn-disable[disabled] {
  cursor: none;
  pointer-events: none;
  color: var(--primary-grey);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    var(--primary-grey);
}

.adhoc-head-background {
  background: linear-gradient(0deg, #eae8ec, #eae8ec);
}
.dynamictable tbody tr:nth-child(odd) {
  background-color: #fafbfb;
}
.dynamictable tbody tr:nth-child(even) {
  background-color: var(--white);
}
.dynamictable tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.dynamictable tr th {
  padding: 14px 0 14px 20px;
  cursor: pointer;
}
.dynamictable tbody tr td {
  padding: 14px 20px 14px 20px;
  cursor: pointer;
}

.dynamictable thead.bottom-border {
  border-bottom: 1px solid #dcdddf;
}

.dynamictableHeight {
  height: 700px;
}
.stockDynamictableHeight {
  height: 255px;
}
.filterNode:hover {
  background-color: #fff !important;
}
.safeBalancePreparedHeight {
  max-height: 245px;
}

.safeBalanceSuspendedHeight {
  height: 165px;
}

.cell-amount-negative {
  color: #dc381f !important;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
}
.cell-amount-positive {
  color: #008000 !important;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
}
.full-width {
  width: 100%;
}

.cell-checkbox-header {
  display: flex;
  align-items: center;
}

.cell-checkbox {
  width: 18px;
  height: 18px;
  margin: 2px 5px;
  border-radius: 4px;
  accent-color: #2e1a47;
}

.search-filter-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #444444;
  border-radius: 4px;
}
.filter-inner-wrapper {
  min-height: 280px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
}
.filter-search-box {
  padding: 14px 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 18px;
  line-height: 28px;
  color: #2e1a47;
  margin: 15px 15px 5px 15px;
}
.row-option-checkbox-list {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #cfd8dc;
  justify-content: flex-start;
  align-items: center;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}
.filter-option-checkbox {
  width: 20px;
  height: 20px;
  margin: 5px 5px;
  border-radius: 4px;
  accent-color: #2e1a47;
}

/*User Menu*/
.view-user-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-user-name {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-right: 10px;
  color: #444444;
}

.user-profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

/*Start*/
/*Amount range input filter Ui styles*/
.container-amount-range {
  display: flex;
  flex-direction: row;
}

.filter-input {
  padding: 15px 15px;
  background: #ffffff;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 69px;
}
.input-amount-range {
  margin: 3px;
  padding: 15px 15px;
  background: #ffffff;
  border: 1px solid #444444;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 69px;
}
.newAlignCss {
  height: 82vh;
  background: #ffffff;
  box-shadow: -4px 0px 8px rgb(0 0 0 / 6%);
}

.warning-icon {
  padding-left: 34%;
  padding-bottom: 9%;
}
/*Amount range input filter Ui styles*/
/*End*/

/*TP balance table css start*/
.balanceTpTable tbody tr td {
  border-bottom: 1px solid #dcdddf;
}
.balanceTpTable tbody tr td,
.balanceTpTable thead th {
  padding: 14px 20px;
}
.balanceTpTable thead th {
  padding: 26px 0px 26px 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
}
/*TP balance table css end*/

.leftSectionBG {
  background: var(--white);
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.08);
}

/* new accounting buttons */
.action-button {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  padding: 19px 56px;
  border-radius: 4px;
}

.button-white {
  color: #2e1a47;
  border: 2px solid#D0D3D4;
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #d0d3d4, #d0d3d4),
    linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  box-shadow: 0px 5px 4px 0px #0000000f;
}

.button-blue {
  color: #ffffff;
  background: #2e1a47;
  box-shadow: 0px 5px 4px 0px #0000000f;
}

.button-red {
  background: var(--primary-blue);
  color: #ffffff;
  box-shadow: 0px 5px 4px 0px #0000000f;
}

.action-button:disabled,
.action-button[disabled] {
  cursor: none;
  pointer-events: none;
  color: #d0d3d4;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
}
/* end */

.standard-label {
  font-size: 24px;
  line-height: 34px;
  color: #222222;
}

.action-label-orange {
  font-size: 21px;
  line-height: 30px;
  color: var(--red);
  font-weight: 700;
}

.action-label-blue {
  font-size: 24px;
  line-height: 34px;
  color: #2b60de;
  font-weight: 700;
}

/* + - sign css */
.circle {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  margin: 4px;
  display: inline-block;
  vertical-align: middle;
}

.blue-border-bg {
  border: 1px solid #2b60de;
  background: #2b60de;
}

.blue-border-bg:disabled,
.blue-border-bg[disabled] {
  border: 1px solid #252a36;
  background: #252a36;
}

.circle.plus:before,
.circle.plus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
}
.circle.plus:before {
  width: 4px;
  margin: 8px auto;
}
.circle.plus:after {
  margin: auto 8px;
  height: 4px;
}

.orange-border-bg {
  border: 4px solid var(--primary-blue);
  background: #ffffff;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.circle.cross:before,
.circle.cross:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-blue);
}
.circle.cross:before {
  width: 4px;
  margin: 5px auto;
}
.circle.cross:after {
  margin: auto 5px;
  height: 4px;
}
.sticky-panel {
  position: sticky;
  bottom: 64px;
}
.scroll-height {
  height: 550px;
}
.header-col-name {
  min-width: 80%;
  white-space: pre-line;
}
.header-column-container {
  align-items: center;
  display: flex;
}
.newAutoHeight {
  height: calc(100vh - 160px);
}
/* end */

/*Branch Selection Styles*/
.branchValidation {
  margin-top: 50px;
  color: #dc381f;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
}
/*branchselection code start here*/
.left-branchselection {
  background-image: url("../assets/images/products.png");
  filter: blur(15px);
  -webkit-filter: blur(15px);
  width: 48%;
  object-fit: cover;
}
.left-branchBackground {
  height: 100%;
  position: absolute;
  left: 0px;
  top: -1px;
  width: 48%;
  background: rgba(0, 0, 0, 0.6);
}
/*branchselection code ends here*/
.branch-selection {
  /* Extra Large */
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  /* /Brand/Purple */
  color: #2e1a47;
}
.branch-acess {
  /* Medium Lead */
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  /* /Brand/Purple */
  color: #2e1a47;
}
.branch-list-of-branch {
  /* Medium Bold */
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */
  /* Typography/Nero */
  color: #222222;
}
.branch-office-heading {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  /* or 111% */

  /* Brand/White */
  color: #ffffff;
}

.branch-wish-heading {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 48px;
  /* or 120% */
  /* Primary/White */
  color: #ffffff;
}

/* end branch selection */
