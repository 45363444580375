.rmDespatch {
  border-color: #e9e9e9;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
}

.rmDespatchSelected {
  border-color: #800080;
  border-width: 2px;
  border-style: solid;
  width: 130px;
}

.count {
  background-color: #e40046;
  display: inline;
  color: #fff;
}

.inputRadioChecbox {
  accent-color: #800080;
  height: 20px;
  width: 20px;
}

.infoLayout {
  border: 1px solid blue;
  border-radius: 2px;
  background-color: #b9cff3;
}

.outerDespatchType {
  width: 130px;
}
