.cancelBtnStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  gap: 10px;
  width: 212px;
  height: 96px;
  margin-right: 10px;
  text-transform: inherit !important;
  margin-bottom: 32px !important;
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 34px !important;
  color: #293462 !important;
  border: 2px solid #293462 !important;
  border-radius: 4px;
  background-color: #fff !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.commitBtnStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  gap: 10px;
  width: 212px;
  height: 96px;
  margin-right: 10px;
  text-transform: inherit !important;
  margin-bottom: 32px !important;
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 34px !important;
  background-color: #293462 !important;
  border: 1px solid #293462;
  border-radius: 4px;
  color: #fff !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.okBtnStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  gap: 10px;
  width: 144px;
  height: 96px;
  margin-right: 10px;
  text-transform: inherit !important;
  margin-bottom: 32px !important;
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 34px !important;
  background-color: #293462 !important;
  border: 1px solid #293462;
  border-radius: 4px;
  color: #fff !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}
