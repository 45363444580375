.error-message {
  font-size: 24px;
  line-height: 34px;
  font-weight: normal;
  color: #dc381f;
  margin-bottom: 8px;
}

.error-border {
  border: 1px solid #dc381f;
}
