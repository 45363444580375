/*Trading Period Popup commit styles*/
.password-validation-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 48px;
  width: 960px;
  background: #ffffff;
}

.password-validation-content {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #444444;
}

.password-validation-form {
  width: 100%;
}
.password-validation-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 43px;
}

.password-validation-label {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #222222;
  margin-bottom: 27px;
}

.password-validation-input {
  background: #ffffff;
  border: 1px solid #222222;
  border-radius: 4px;
  width: 680px;
  height: 72px;
  padding: 14px 16px;
  font-size: 24px;
}

.password-validation-input-error,
.password-validation-input-error:focus {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ff808b;
  border: 2px solid #dc381f !important;
  border-radius: 4px;
}

.password-validation-input:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
}

.password-validation-label-info {
  height: 33px;
  width: 33px;
  margin-left: 26px;
}

.password-validation-login-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.password-validation-btn-cancel {
  box-sizing: border-box;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  background: #ffffff;
  color: #2e1a47;
  font-size: 24px;
  border-radius: 4px;
  border: 2px solid #d0d3d4;
  height: 96px;
  padding: 0px 56px;
}
.password-validation-btn-submit {
  box-sizing: border-box;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  background: #e40046;
  border-radius: 4px;
  color: #ffffff;
  font-size: 24px;
  height: 96px;
  padding: 0px 56px;
}

.password-validation-btn-submit:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
  color: #d0d3d4;
  border: 2px solid #d0d3d4;
  cursor: default;
}
.password-warning-label {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: #dc381f;
  width: 680px;
}
