.txtStyle {
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 34px !important;
  height: 136px;

  text-align: center;
  color: #444444 !important;
}

.btnContainer {
  justify-content: center !important;
}

.btnStyle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  gap: 10px;
  width: 212px;
  height: 96px;
  margin-right: 10px;
  text-transform: inherit !important;
  margin-bottom: 32px !important;
  font-family: "Nunito Sans" !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 34px !important;
  background-color: #293462 !important;
  border-radius: 4px;
  color: #fff !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}
