.WarningMsgTaskListPage {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)),
    var(--warningYellow);
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  height: 74px;
  position: absolute;
  right: 0;

  flex-direction: row;
  align-items: center;
  padding: 0px;
  border: 1px solid var(--warningYellow);
  border-radius: 4px;
}
.WarningMsgTaskListPage span {
  display: inline-flex;
  padding: 20px 10px 0px 15px;
}
