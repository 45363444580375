@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,800,800i,900i&display=swap");
@import "../../screens/_Styles_/variable.css";
body {
  font-family: "Nunito Sans", sans-serif !important;

  -webkit-font-smoothing: antialiased;

  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-grey);
}

/* html {
  @apply bg-gray-100;
} */
amplify-hint {
  --display: none !important;
}
:root {
  --amplify-primary-color: #d71440;
  --amplify-primary-tint: #ba112d;
  --amplify-primary-shade: #ba112d;
}
.containerMain {
  max-width: 100%;
  height: calc(100vh - 96px);
}
.login-bg {
  background-image: linear-gradient(rgba(70, 70, 70, 0.75), rgba(255, 255, 255, 0.45)),
    url("../images/branch-bg.jpg");
  background-size: cover;
}
.loginContainer {
  border: 1px solid #d0d3d4;
  justify-content: center;
  align-items: center;
  margin: 80px auto;
  background: #fff;
  flex: 2 1;

  width: 454px;
  text-align: center;
  padding: 40px;

  vertical-align: top;
}
.MainHeading {
  min-width: 140px;
  min-height: 18px;
  left: 0px;
  top: 117.5px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 18px;
  /* identical to box height, or 56% */

  color: #222222;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 29px 0px;
  /* margin-bottom: 30px; */
}
.loginTop {
  position: relative;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 29px 0px;
  height: auto;
  /* height: 25vh; */
  vertical-align: bottom;
}
.Loginlogo {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}
.Loginlogo img {
  margin: 0 auto;
  padding: 0;
}
.SubHeading {
  text-align: center;
  min-width: 109px;
  min-height: 18px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #222222;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 29px 0px;
}
.alertMsg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  width: 80%;
  margin: auto;
  min-width: 334px;
  min-height: 61px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #ff0000;
  background: rgba(255, 128, 139, 0.1);
  border: 1px solid #dc381f;
  box-sizing: border-box;
}
auth-container {
  --padding: 0;
  --margin: 0;
  --min-height: inherit;
  display: inline-block;
}
amplify-authenticator {
  --background-color: transparent;
  --box-shadow: none;
  --border-radius: 0;
  --padding: 0;
  --margin: 0;
  --width: 100%;
  --text-align: center;
  --vertical-align: top;
  --container-height: auto;
  --display: inline-block;
}

amplify-sign-in {
  background: transparent;
  --footer-font-size: 10em;
  --padding: 0;
  --margin: 0;
}
amplify-input {
  --padding: 10px;
  --height: 45px;
  height: 10px;
  --border: 1px solid #222222;
}
.toast {
  --background-color: "#dc262" !important;
}

.makeStyles-colorPrimary-2 {
  background-color: #d1d5db;
}

.lableSmart {
  color: #152939;
  font-size: 0.875rem;
  margin-bottom: 16px;
  padding-bottom: 0.5em;
}
.labelDiv {
  display: block;
  font-size: var(--label-font-size);
  padding-bottom: 0.5em;
}
.loginInput {
  display: block;
  width: 100%;
  outline: none;
  padding: 1rem;
  font-size: 0.875rem;
  color: #152939;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 0.625rem 0;
  height: 3.125rem;
  line-height: 1.1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.formLogin {
  text-align: left;
  padding: 10px 25px 0 25px;
}
.loginBtn {
  margin-top: 10px;

  width: 100%;
  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  color: #ffffff;
  padding: 1rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  background-color: #d71440;
  border-width: 0;
  border-color: initial;
  border-style: initial;
  border-radius: 4px;
}

.loginBackground {
  background: #f6f8f9;
}
.marginTop10 {
  top: 63px;
}

@media screen and (max-width: 900px) {
  .makeStyles-drawer-11 {
    width: 0px !important;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 360px) {
  .containerTop {
    padding: 0 !important;
  }
  .loginContainer {
    background: #ffffff;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    background: #fff;
    border-radius: 10px;
    flex: 2 1;
    max-width: 355px;
    min-width: 355px;
    text-align: center;
    padding: 15px;

    vertical-align: top;
  }
  .loginTop {
    max-width: 355px;
    min-width: 355px;
    position: relative;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
    height: auto;
    vertical-align: bottom;
  }
  .MainHeading {
    min-width: 140px;
    min-height: 18px;
    left: 0px;
    top: 117.5px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 18px;
    color: #222222;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
  }

  .maintasklistouter {
    overflow: auto !important;
    height: 600px;
    min-width: 360px !important;
  }
  .shadowimg {
    box-shadow: 0px 0px 10px 0px;
    bottom: 15px;
    right: 5px !important;
    width: 50px !important;
    background-color: #6f6cbd;
  }
}

@media screen and (max-width: 768px) {
  .dynamictable {
    width: 1100px;
  }
  .heading-3 {
    font-size: 1.4rem !important;
  }
}

@media screen and (max-width: 600px) {
  .containerTop {
    /* overflow: hidden !important; */
    padding: 0 !important;
  }
  .loginContainer {
    margin: 50px auto;
    max-width: 100%;
    width: auto;
  }

  .maintasklistouter {
    overflow: auto !important;
    height: 600px;
    min-width: 600px;
  }

  .shadowimg {
    box-shadow: 0px 0px 10px 0px;
    bottom: 15px;
    right: 5px !important;
    width: 65px !important;
    background-color: #6f6cbd;
  }
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.showMobile {
  display: none !important;
}

.showDesktop {
  display: block;
}

@media screen and (max-width: 1023.5px) {
  .showMobile {
    display: block !important;
  }
  .showDesktop {
    display: none;
  }

  .viewtabs li.active {
    padding: 0px;
  }

  .viewtabs li {
    padding: 0px !important;
    border-bottom: 1px solid #b5b5b5;
  }
  .widthAccounting {
    width: 100% !important;
  }
  .viewtabs {
    border-bottom: none !important;
  }
  .adhod-heading {
    font-size: 14px !important;
    line-height: 0px !important;
  }
  .varianceBox {
    width: 100% !important;
  }
}

/**
Cash Balance History styles
*/
.ui-table-view .uppercase,
.ui-table-view .uppercase {
  text-transform: capitalize;
}

.ui-table-view th {
  color: #222222;
  background-color: #252a36;
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  background: #e9eaeb;
}
.css-9i9073-control {
  box-shadow: 0px 0px 0px 1px #444 !important;
}

.varianceSummary .ui-table-view tr {
  /* height: auto; */
  min-height: 52px;
}

.varianceSummary .ui-table-view th {
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.ui-dropdown,
.date-range {
  border: 1px solid;
  border-radius: 2px;
}

.btn-link {
  color: #2b60de;
  text-decoration: underline;
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
  cursor: pointer;
}
.ui-label-light {
  /* color: #b6b6b4;
  font-size: 16px; */
  color: #222222;
  font-size: 14px;
  line-height: 21px;
}

.po-date-picker {
  display: flex;
  flex-direction: row;
}

.po-date-range .error-text {
  color: #dc381f;
  font-size: 12px;
}
.po-date-picker .innerContainer {
  position: relative;
}

.po-date-picker:first-child .innerContainer {
  margin-right: 20px;
}
.po-date-picker .innerContainer i {
  background-color: white;
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 24px;
  cursor: pointer;
  pointer-events: none;
}

/**
Branch Overview screen
*/
.horizontal-tab-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d4d7;
}
.horizontal-tab-list ul {
  list-style: none;
  padding: 10px 0px;
  margin: 0px;
}
.horizontal-tab-list .tab-listview {
  display: inline;
  padding: 10px 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #222222;
  /* background: #ffffff; */
  line-height: 28px;
}

.horizontal-tab-list .tab-listview.active,
.horizontal-tab-list .tab-listview:hover {
  border-bottom: 3px solid var(--primary-blue);
  color: var(--primary-blue);
}

.popup-content-msg {
  font-size: 1.5rem;
  white-space: break-spaces;
  color: #222222;
}

.print-view {
  display: none;
}
.print-outer {
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.print-container {
  padding: 3mm;
  width: 80mm;
  justify-content: center;
  align-items: center;
}
.title {
  font-size: 16pt;
  font-weight: bold;
  text-align: center;
}
.subtitle {
  text-align: center;
}

#barcode {
  display: block;
  margin: 0px auto;
}

#barcode:empty {
  display: none;
}

.container-margin {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 13px;
}

.border-black {
  border-color: var(--primary-black);
}
