.container1 {
  padding: 40px;
}
.total-children {
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: bolder;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  padding-left: 10px;
  padding-top: 10px;
}
.total-container {
  /* padding: 12px; */
  display: flex;
}

.total {
  flex-grow: 7;
  text-align: inherit;
}

.empty {
  flex-grow: 7;
}

.quantity {
  padding-right: 21%;
  float: right;
  text-align: end;
}

.value {
  float: right;
  padding-right: 14px;
  text-align: end;
}

.empty1 {
  flex-grow: 2;
}
