@import "../../screens/_Styles_/variable.css";
.bg-color-stock {
  background-color: var(--primary-grey);
}
.nav-heading {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  margin-top: 28px;
  margin-left: 42px;
}
.nav-sub-heading {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  margin-top: 28px;
}

.categoryList {
  margin-left: 42px;
  margin-top: 23px;
  margin-right: 32px;
}
.bg-select-product {
  background-color: #2e1a47;
  color: #ffffff;
}
.max-h-0 {
  max-height: 0;
}
.group:focus .group-focus\:max-h-40 {
  max-height: 10rem;
}
.stock-item:hover {
  background-color: #2e1a47;
}
