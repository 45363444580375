.print-view_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Nunito Sans";
  font-style: normal;
}
.print-view_wrapper .pouch-print-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 20px 40px;
  grid-gap: 10px;
  gap: 10px;
  position: relative;
  width: 302px;
  height: 637px;
  background: #ffffff;
  /* border: solid 2px #0074CC; */
}
.pouch-print-container .header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  gap: 15px;
  padding: 15px;
}
.pouch-print-container .content {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pouch-print-container .pouch-preparation-headiing {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  width: 100%;
}
.pouch-print-container .pouch-preparation-headiing ul {
  display: flex;
  justify-content: space-between;
}

.pouch-print-container .pouch-preparation-denomination-headiing {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  width: 100%;
}
.pouch-print-container .pouch-preparation-denomination-headiing ul {
  display: flex;
}
.pouch-print-container .pouch-preparation-denomination-headiing ul .width_45_per {
  width: 45%;
}
.pouch-print-container .pouch-preparation-denomination-headiing ul .width_10_per {
  width: 10%;
}

.pouch-print-container .header p {
  font-size: medium;
}

.pouch-print-container .content .domination_ladder_title {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  width: 100%;
  justify-content: center;
}
.pouch-print-container .line {
  width: 262px;
  height: 0px;
  border-top: 1px dashed #000000;
}
.pouch-print-container .footer {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #000000;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
}

.pouch-print-container .content .consignment_receiving_title {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: flex-start;
  color: #000000;
  width: 100%;
}
.pouch-print-container .content .consignment_receiving_wrapper {
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 10px;
  color: #000000;
  gap: 10px;
  flex-direction: column;
}

.pouch-print-container .content .consignment_receiving_wrapper .consigmnet_receiving_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}
.pouch-print-container .content .consignment_receiving_wrapper .consigmnet_receiving_content ul {
  flex: 1;
  display: flex;
  gap: 20px;
}
.pouch-print-container .content .consignment_receiving_wrapper .consigmnet_receiving_content ul li {
  flex: 1;
}

.pouch-print-container .content .consignment_receiving_wrapper .completed_by {
  display: flex;
  /* grid-gap: 5px; */
  gap: 5px;
  /* justify-content: center; */
  align-items: center;
  justify-content: space-between;
}
.pouch-print-container .content .consignment_receiving_wrapper .completed_by input {
  box-sizing: border-box;
  width: 96px;
  height: 96px;
  border: 0.5px solid #000000;
}
/*****************************************************************/

/*3px red then 4px transparent -> repeat this!*/

.pouch-prepare-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  gap: 32px;

  font-family: "Nunito Sans";
  font-style: normal;
  background: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.pouch-prepare-popup .messageBody {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #444444;
}
.pouch-prepare-popup .cancelBtn {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  gap: 10px;
  width: 187px;
  height: 96px;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid #293462;
  font-weight: 700;
  font-size: 24px;
  color: #2e1a47;
}

.pouch-prepare-popup .success-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  gap: 15px;
  background: #293462;
  border-radius: 4px;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
}
.pouch-prepare-popup .print-receipt-btn {
  width: 256px;
  height: 96px;
}

.pouch-prepare-popup .successful-print-btn {
  width: 386px;
  height: 96px;
}
.pouch-prepare-popup .reprint-success-btn {
  width: 195px;
  height: 96px;
}
.pouch-prepare-popup .reprint-btn {
  box-sizing: border-box;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  gap: 10px;

  width: 195px;
  height: 96px;

  /* Primary/White */

  background: #ffffff;

  border: 2px solid #293462;
  border-radius: 4px;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #293462;
}

.pouch-preapre-wrapper .pouchPreparationPopupDimension {
  min-width: 760px;
  min-height: 338px;
}
.pouch-preapre-wrapper .confirmSuccessPrintPopupDimension {
  min-width: 960px;
  min-height: 338px;
}
.pouch-preapre-wrapper .reprintConfirmationPopupDimension {
  min-width: 560px;
  min-height: 338px;
}
