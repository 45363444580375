@import "../../screens/_Styles_/variable.css";

.forex-ladder .description {
  display: flex;
  height: 100%;
  align-items: center;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  margin-left: 77px;
  text-align: center;
}

.textCashType {
  margin-right: 10px;
}

.forex-denomination {
  height: 100%;
  background-color: var(--white);
}

.submit-button {
  width: 150px;
  height: 46px;
  background: var(--primary-blue);
  color: var(--white);
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  margin-top: 6px;
  border-radius: 4px;
}

.right-navigation {
  background-color: var(--white);
}

.footeralign {
  padding-left: 0px !important;
}

thead tr th {
  font-weight: 500;
  /* min-height: 100%; */
  text-align: left;
  background: var(--primary-blue);
  color: var(--white);
}

.footer1 {
  background-color: #ffffff;
  width: 50%;
  height: 60px;
  color: white;
  position: fixed;
  box-shadow: 2px -2px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 0%;
}

.centered {
  /* width: 100%; */
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.typography {
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.right-denominationdiv {
  /* overflow-y: scroll;  */
  height: 640px;
  background-color: var(--white);
  /* display: block; */
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 50px;
}

.right-denomination {
  overflow-y: auto;
  position: fixed;
  background-color: var(--white);
  padding: 0%;
  height: calc(100vh - 60px);
}

.tick-container {
  font-size: 32px;
  position: relative;
}

.tick {
  position: absolute;
  top: -70px;
  right: -10px;
  color: green;
}

.submit-button-disable {
  width: 150px;
  height: 46px;
  background: #d0d3d4;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  margin-top: 6px;
  border-radius: 4px;
}
