.progressText {
  padding: 3px 20px;
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  font-family: "Nunito Sans";
  white-space: nowrap;
}

.ParentDiv {
  font-size: 1.2rem;
  height: 30px;
  width: 100%;
  background-color: #dddddd;
  border-radius: 40px;
}
.ChildDiv {
  height: 100%;
  border-radius: 40px;
}
.weightText {
  color: #fff;
}
.height30 {
  height: 30px;
}
