.accordionTitleName {
  justify-content: space-between;
  display: flex;
  font-size: 1.2rem;
}
.TPColor {
  background-color: transparent !important;
}
.width-15 {
  width: 15%;
}

.linkBtnHighLevel {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #2b60de;
  text-align: left;
}
.linkBtn {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  color: #2b60de;
}

.ContentDetailAlign tbody tr {
  background-color: transparent !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}
.ContentDetailAlign tbody tr td {
  padding: 5px 25px 8px 5px !important;
  border-bottom: none !important;
}
.dot {
  vertical-align: middle;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}
.valueDot {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: right;
}
.sideTitle {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.TPContent {
  width: 99.4%;
}
.ContentDetailAlign {
  max-height: 150px;
  overflow: auto;
}
.ContentDetailAlign::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ContentDetailAlign::-webkit-scrollbar-track {
  background: #eae8ed;
}

/* Handle */
.ContentDetailAlign::-webkit-scrollbar-thumb {
  background: #32174a;
  height: 30px;
}
.detailViewHeader .horizontal-tab-list {
  padding: 0px 10px;
  position: relative;
  margin: 10px 0px 0px 3px;
  width: 99.6%;
  padding: 5px 0 5px 0px;
  overflow-x: hidden;
  white-space: nowrap;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(208, 211, 212, 1);
  box-sizing: border-box;
  height: 88px;
}

.detailViewHeader .horizontal-tab-list-inner {
  padding: 0px 12px 0px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.detailViewHeader .horizontal-tab-list ul {
  width: 98%;
}

.detailViewHeader .horizontal-tab-list ul .tab-listview {
  border-bottom: none !important;
  font-weight: 400;
  font-size: 18px;
  border-right: 1px solid rgba(208, 211, 212, 1);
  line-height: 28px;
  padding: 0px 33px 0px 10px;
  min-width: calc((80% / 8) - 0.125em);
  max-width: calc((95% / 8) - 0.125em);

  word-break: normal;
  white-space: normal;
}
.detailViewHeader .horizontal-tab-list ul .tab-listview:last-child {
  border-right: none !important;
}
.detailViewHeader .horizontal-tab-list ul .tab-listview:first-child {
  margin-left: 30px;
}
.detailViewHeader .horizontal-tab-list .left {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.detailViewHeader .horizontal-tab-list .right {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.tab-listview-trading-period {
  display: inline;
  padding: 10px 50px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  font-size: 18px;
  color: #444444;
  border-right: 2px solid #cfc9c9 !important;
  border-bottom: none !important;
  line-height: 28px;
  background: #ffffff;
}

.tab-listview-trading-period:hover,
.tab-listview-trading-period.active {
  border-bottom: 3px solid var(--primary-blue);
  color: var(--primary-blue);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}

.disableColor {
  color: #b6b6b4;
}
.completeButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  border-radius: 4px;
  height: 72px;
  background: var(--primary-blue);
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
}
.completeButton:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
  color: #d0d3d4;
}
.popUpBalance {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #444444;
}
.cancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;
  border-radius: 4px;
  height: 72px;
  background: #fff;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  border: 2px solid #d0d3d4;
  color: #2e1a47;
}
.warning {
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #222222;
}
.accordian-title {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #444444;
}
.subTitle {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #444444;
}
