/*Trading Period Popup commit styles*/
.tp-popup-commit-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 48px;
  background: #ffffff;
  width: 960px;
}
.tp-popup-header-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tp-warning-icon {
  height: 72px;
  width: 72px;
  margin-bottom: 40px;
}
.tp-popup-title {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #222222;
}
.tp-popup-content {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #444444;
}

.tp-login-form {
  width: 100%;
}
.tp-login-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 43px;
}

.tp-login-label {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: #222222;
}

.tp-login-input {
  background: #ffffff;
  border: 1px solid #222222;
  border-radius: 4px;
  width: 680px;
  height: 72px;
  padding: 14px 16px;
  margin-top: 27px;
  font-size: 24px;
}

.tp-login-input:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
}

.tp-label-info {
  height: 33px;
  width: 33px;
  margin-left: 26px;
}

.tp-login-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
}

.tp-btn-cancel {
  box-sizing: border-box;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  background: #ffffff;
  color: #2e1a47;
  font-size: 24px;
  border-radius: 4px;
  border: 2px solid #d0d3d4;
  height: 96px;
  padding: 0px 56px;
}
.tp-btn-submit {
  box-sizing: border-box;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  background: var(--primary-blue);
  border-radius: 4px;
  color: #ffffff;
  font-size: 24px;
  height: 96px;
  padding: 0px 56px;
}

.tp-btn-submit:disabled {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
  color: #d0d3d4;
  border: 2px solid #d0d3d4;
  cursor: default;
}
.tp-popup-content ul {
  margin-left: 40px;
  list-style: disc;
}

.commit-success-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  gap: 32px;

  width: 560px;
  height: 344.12px;

  background: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.commit-success-popup .ok-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 56px;

  width: 147px;
  height: 96.12px;

  /* Brand/Red */

  background: var(--primary-blue);
  /* Button Shadow */

  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height, or 142% */

  display: flex;
  align-items: center;

  /* Brand/White */

  color: #ffffff;
}
.commit-success-popup .messageBody {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #222222;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.popUp-Overlay {
  background: rgba(34, 34, 34, 0.5);
}
