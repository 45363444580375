.pagination-container {
  display: flex;
  list-style-type: none;
}
.pagination-container .pagination-item {
  border: 1px solid #b6b6b4;
  border-radius: 4px;
  padding: 14px 16px;
  height: 56px;
  text-align: center;
  margin: auto 4px;
  color: #222222;
  box-sizing: border-box;
  line-height: 28px;
  font-size: 18px;
  font-weight: 400;
  min-width: 60px;
}
.pagination-container .pagination-item.dots {
  border: none;
  padding: 0px 5px;
  min-width: 0px;
}

.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: #ffffff;
  cursor: pointer;
}
.pagination-container .pagination-item {
  background-color: #ffffff;
  color: #222222;
}
.pagination-container .pagination-item.selected {
  background-color: #ffffff;
  color: #222222;
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt;
  Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
