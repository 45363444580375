.horizontal-tab-list {
  position: relative;
  padding-right: 1.5rem;
  top: 11px;
}
.horizontal-tab-list .tab-listview {
  cursor: pointer;
  font-size: 24px;
}
.horizontal-tab-list .tab-listview:hover,
.horizontal-tab-list .tab-listview.active {
  border-bottom: 3px solid var(--primary-blue);
  color: var(--primary-blue);
}
.horizontal-tab-list-summary {
  display: flex;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  top: 11px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d3d4d7;
  padding-left: 17px;
  margin-left: 19px;
  margin-right: 19px;
  line-height: 62px;
  box-shadow: 0px 2px 5px #888;
}
.horizontal-tab-list ul {
  list-style: none;
  padding: 10px 0px;
  margin: 0px;
  box-sizing: border-box;
}

.horizontal-tab-list-summary ul {
  list-style: none;
  padding: 10px 0px;
  margin: 0px;
  box-sizing: border-box;
}
.hideDiv .ui-table-view .divDummy {
  display: none;
}
.horizontal-tab-list-summary .tab-listview-discrpency {
  border-right: 2px solid #cfc9c9;
  cursor: pointer;
  display: inline;
  padding: 0px 45px 3px 45px;
  margin: 0px 10px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #222222;
  background: #ffffff;
}
.horizontal-tab-list-summary .tab-listview-discrpency:last-child {
  border-right: none;
}

.horizontal-tab-list-summary .tab-listview-discrpency.active {
  color: var(--primary-blue);
}
.horizontal-tab-list-summary .tab-listview-discrpency:hover {
  color: var(--primary-blue);
}

.headingHome {
  font-size: 18px;
  display: inline-flex;
  font-weight: 700;
  line-height: 28px;
  padding-right: 85px;
}
.headingSummary {
  white-space: nowrap;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}
.resultShown {
  white-space: nowrap;
  display: flex;
  line-height: 36px;
  font-size: 16px;
  padding-top: 9px;
  padding-right: 6px;
}

.DetailHeadingText {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}
.DetailSideHeadingText {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
  font-size: 24px;
  line-height: 34px;
  color: #2b60de;
}
.DetailViewtTable {
  max-width: 666px;
}
.DetailViewtTable tbody tr:nth-child(even) {
  background-color: #fafbfb;
}
.DetailViewtTable tr td {
  padding: 10px;
  height: 52px;
}
.rightTextLight {
  width: 50%;
  min-width: 101px;
  height: 28px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}
.leftTextStyle {
  width: 50%;
  min-width: 234px;
  height: 28px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.shadowDetailsView {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.commentStyleBtn {
  color: #000;
  padding: 14px 20px;
  gap: 10px;
  text-align: center;
  width: 144.22px;
  height: 55.3px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
}
.ExitPreviewStyle {
  width: 251px;
  height: 72px;
  color: #fff;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  background: #2e1a47;
  border-radius: 4px;
}
.accounting-button-discrepancy {
  background: #e40046;
  box-shadow: 0px 7.58px 6.06px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.accounting-button-discrepancy:disabled,
.accounting-button-discrepancy[disabled] {
  opacity: 0.5;
  cursor: none;
  pointer-events: none;
}
.accountingBtnNoHover {
  background: var(--primary-blue);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
}
.text-color {
  color: #222;
}

.accounting-button-discrepancy:hover {
  opacity: 0.7;
}

.RightalignBtn {
  width: 166px;
  height: 72px;
}

.newRightalignBtn {
  position: absolute;
  right: 0;
}
.popupModelHighLevel {
  width: 560px;
  height: 260.12px;
  bottom: 110px;
}
.restNoBtnMcdHighLevel {
  height: 75px;
  width: 38%;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
}
.brandRedBtnMcdHighLevel {
  height: 75px;
  width: 38%;
  background-color: var(--primary-blue) !important;
  padding: 14px 24px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
}
.amountHighLevel {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
}

.mainlist {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #d0d3d4;
  height: auto;
  min-width: 800px;
}

.brandRedBtnMcdHighLevel[disabled] {
  opacity: 0.5;
}

.listStyle {
  overflow: auto;
  width: 464px;
  max-height: 205px;
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
}
.DetailViewtTable .breakDownWrapper {
  width: 140px;
  min-width: 140px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}
.DetailViewtTable .breakDowntitle {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2b60de;
  flex: none;
  flex-grow: 0;
}

.DetailViewtTable .headerRow {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2e1a47 !important;
  border-top-width: 2px;
  border-bottom-width: 2px;
}
.correction-Narrative {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 5px;
  width: 100%;
  gap: 16px;
  height: 300px;
  background: #ffffff;
  border: 1px solid #444444;
  border-radius: 4px;
}
