.itemList,
.subItemList {
  font-size: 21px;
  line-height: 30px;
  color: #222222;
  border-bottom: 1px solid rgba(229, 231, 235, 1);
  padding: 13px;
}

.subItemList {
  list-style-type: none;
}

.itemList:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.itemList:last-child,
.subItemList:last-child {
  border-bottom-width: 0;
}

.top-border-light {
  box-shadow: 2px -2px 8px 0px rgba(0, 0, 0, 0.25);
}

.bottom-container {
  min-height: 450px;
}

.show-option {
  display: block;
  background: #2e1a47;
  color: #ffffff;
}

.show-option:hover {
  --tw-bg-opacity: 1;
  background-color: #2e1a47;
}

.hide-option {
  display: none;
}

.body-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 64px);
}
.resumeButton {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: #ff9500;
  border-radius: 100px;
}
.resumeLabel {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}
.actionPanel {
  display: flex;
  justify-content: right;
}
