.userActivitiesTableHeight {
  max-height: 500px;
  overflow: auto;
}
.userActivitiesTableHeight tr th {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 32px !important;
}
.userActivitiesTableHeight tbody tr td div {
  height: 30px !important;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px !important;
  line-height: 32px !important;
  height: 30px;
  display: inline-block;
}

.cashExchangeHeight {
  /* background-color: #ffffff; */
  height: calc(100vh - 110px);
}
