.popupBtn {
  background: #ffffff;
  box-shadow: 0px 7.58px 6.06px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: #2e1a47;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  font-weight: 700;
  border: 1.5px solid #d0d3d4;
}

.popupTitle {
  font-size: 24px;
  line-height: 34px;
}

.detailViewTable tbody td {
  height: 52px;
  padding-left: 20px;
  padding-right: 20px;
}

.detailViewTable tbody tr.subHead {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2e1a47;
}

.detailViewTable tbody tr:last-child {
  --tw-divide-y-reverse: 1 !important;
  border-top-width: calc(1px * var(--tw-divide-y-reverse)) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}

.popUp-button {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  padding: 30px 56px;
  border-radius: 4px;
}

.red-button {
  background: var(--primary-blue);
  color: #ffffff;
  border: 2px solid var(--primary-blue);
}

.white-button {
  background: #ffffff;
  color: #2e1a47;
  border: 2px solid #d0d3d4;
}

.popUp-button:disabled,
.popUp-button[disabled] {
  cursor: none;
  pointer-events: none;
  color: #d0d3d4;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #d0d3d4;
}

.popup-alert-text {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #222222;
}
