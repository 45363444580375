h1,
p {
  font-family: Nunito Sans;
}
table {
  width: 100%;
  position: relative;
  border-collapse: collapse;
}

thead tr th {
  font-weight: 500;
  text-align: left;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #252a36;
}
.noRecords {
  font-family: "Nunito Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.TableFontStyle {
  width: 152px;
  height: 32px;
  display: inline;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.linkTag {
  color: #2b60de;
}
.scrollHeight {
  max-height: 200px;
}
.filterDropBoxShadow {
  border: 1px solid #444444;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
}

.noRecordFound {
  background-color: transparent !important;
  font-family: "Nunito Sans";
  height: 500px;
  letter-spacing: 0em;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #293462;
}

.noRecordFoundDynamo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newTheme thead tr th {
  background: #293462 !important;
  color: #ffffff;
}
.printBtn {
  display: inline-flex;
  position: fixed;
  right: 25px;
  top: 80px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  color: var(--blue);
}
.printicon {
  display: inline-flex;
  position: fixed;
  right: 83px;
  top: 80px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
}
.printBtn span {
  margin-right: 10px;
}
.print {
  color: var(--disablegrey);
}
