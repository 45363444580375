.dropdown-container {
    margin-bottom: 2px;
    position: relative;
    right: 5px;
  }
  
  .input-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 72px;
    border-radius: 5px;
    border: 3px solid #293462;
  }
  
  .input-container input {
    flex-grow: 2;
    outline: none;
    padding-left: 12px;
    font-size: 24px;
  }
  
  .x-icon-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: end;
    min-width: 72px;
    outline: none;
    padding-right: 15px;
  }
  
  .search-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #293462;
    color: white;
    min-width: 72px;
    transform: scale(1.01);
  }
  
  .search-icon {
    color: white;
    transform: scale(1.8);
  }
  .x-icon {
    color: #293462;
    transform: scale(1.5);
  }
  
  .dropdown {
    border-radius: 75px;
    display: none;
  }
  
  .dropdown.visible {
    display: block;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    right: 0;
    top: 77px;
    z-index: 100;
  }
  
  .option {
    height: 72px;
    border-bottom: 2px solid rgba(79, 79, 79, 0.263);
    background-color: white;
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
    width: 100%;
    font-size: 24px;
  }
  .option:hover {
    background-color: #293462;
    color: white;
  }
  